@import './variables';

#projects-page {
	// -------------------- HEADER ------------------------------
	.page-description {
		svg {
			font-size: 3rem;
			margin: 15px auto;
		}

		a {
			padding: 5px;
			color: $lightblue
		}

		p:nth-child(2) {
			font-size: 1.5rem;
			font-weight: 400;
			margin-bottom: 0;
		}

		p:nth-child(3) {
			font-size: 0.8rem;
		}
	}
	// -------------------- TECH ------------------------------
	.page-content {
		padding-top: 0;
	}

	#tech {
		background-color: $lightblue;
		color: white;
		padding: 35px;
		filter: drop-shadow(0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.4));

		h4 {
			color: inherit;
			padding: 20px;
			font-weight: 400;
		}
	}

	.tech-details {
		display: flex;
		flex-direction: column;
		align-items: center;

		svg {
			font-size: 2rem;
		}

		p {
			padding: 10px;
			font-size: 0.8rem;
		}
	}
	// -------------------- PROJECTS ------------------------------
	#projects {
		padding: 25px;

		img:hover {
			filter: brightness(70%) drop-shadow(0.2rem 0.4rem 0.5rem rgba(0, 0, 0, 0.3));
		}

		.project-details {
			padding: 20px;
		}

		a {
			padding: 0;
			position: relative;
			display:block;
		}

		.project-legend {
			background-color: rgba(0,0,0,0.5);
			color: white;
			position: absolute;
			bottom: 0;
			width: 100%;
			padding: 10px 0;

			p {
				text-align: center;
				margin: 0;
			}

			p:nth-child(1) {
				font-weight: 400;
			}


			p:nth-child(2) {
				font-size: 0.7rem;
			}
		}
	}

	#more-projects {
		background-color: $lightblue;
		text-align: center;
		color: white;
		font-size: 1.5rem;
		padding: 25px;
	}
}
