@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');

@import './variables';

// -----------------GENERAL BODY SETTINGS---------------------------------

html{
  scroll-behavior: smooth;
}

body {
	box-sizing: border-box;
	font-family: 'Oxygen', sans-serif;
	color: $standardfontcolor;
	overflow-x: hidden;
	position: relative;
}

.App{
	min-height: calc(100vh - #{$navbar-height});
	background-color: $lightgray;
}

h1,h3 {
	font-family: 'Fredericka the Great', sans-serif;
}

.row{
  margin-left: 0;
  margin-right: 0;
}


// ----------------SUBPAGES HEADER SETTINGS---------------------------------

.page-container{
	margin-top: $navbar-height;
	font-weight:200;
	height:100%;
}

.page-header {
	text-align: center;
	min-height: 25vh;
	background-color: white;
	filter: drop-shadow(0rem 0.25rem 1rem rgba(0, 0, 0, 0.1));
}

.page-title{
	background-color: $darkblue;
	display: flex;

	h3{
		margin:auto;
		color: white; 
		font-size: 3rem;
		padding-bottom: 20px;
	}
}

.page-description{
	margin: 40px auto;
	display: flex;
	flex-direction:column;
	justify-content:center;

	p:first-child{
		font-size: 1.5rem;
	}
}


// ----------------SUBPAGES CONTENT SETTINGS ----------------------------------
.page-content{
	padding-top: 60px;
	padding-bottom: $footer-height-min;
}

h4{
	font-size : 2rem;
	font-weight:700;
	padding: 10px;
	margin-bottom: 30px;
	color:$darkblue;
	text-align: center;
	text-transform: capitalize;
}


a{
	color: inherit;
}

a:hover{
  text-decoration: none;
  color:inherit;
}

// ----------------MEDIA QUERIES ----------------------------------


@media (max-width: 767.98px){
	.page-content{
		padding-bottom: $footer-height-max;
	}

	#title h1{
		font-size: 3.2rem;
	}

	.App nav {
		filter:initial;
	}

}

@media (min-width: 768px){
	.page-title{
		min-height:200px;
	}
}

@media (min-width: 2000px){

	.page-title{
		p{
			margin-right:15%;
		}
	}
}

