@import './variables';

.contact-page {
	// -------------------- PAGE HEADER ------------------------------
	svg {
		color: silver;
	}

	#contact-icons {
		margin-bottom: 20px;

		a {
			display: inline-block;
			padding: 10px;
			background-color: $darkblue;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			margin: 10px;

			svg {
				color: white;
			}
		}
	}

	#location {
		margin: 0;

		span {
			padding-left: 15px;
		}
	}
	// -------------------- FORM ------------------------------
	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	h4 {
		font-weight: 400;
	}

	abbr {
		font-size: 0.6rem;
		vertical-align: top;
		text-decoration: none;
	}

	form {
		margin-top: 10px;
		margin-bottom: 70px;
		width: 100%;
	}

	button {
		background-color: $lightblue;
		border-radius: 0.25rem;
		border: none;
		margin-top: 25px;
	}

	button:hover, button:active, button:focus {
		background-color: $darkblue;
		outline: none;
		box-shadow: none;
	}

	@media (min-width: 768px) {

		form {
			width: 80%;
		}
	}

	@media (min-width: 992px) {

		form {
			width: 50%;
		}
	}
}

