@import './variables';


#about-page {
	// -------------------- EXPERIENCE & EDUCATION SECTION ------------------------------
	.about-category {
		padding-bottom: 40px;
	}

	.about-details {
		white-space: pre-wrap;
		margin-top: 20px;
		margin-bottom: 40px;

		p {
			margin: 0;
		}

		div:first-child {
			font-weight: 400;
			padding-bottom: 15px;
		}
	}

	.date-place {
		padding-top: 5px;
		padding-bottom: 10px;
		font-size: 0.9rem;
		text-align: right;
	}

	.about-description {
		font-size: 0.9rem;
		text-align: justify;
	}
	// -------------------- LANGUAGES SECTION ------------------------------
	#languages {
		margin-bottom: 50px;
	}

	.bar {
		justify-content: center;
		text-align: right;
		margin-right: 30px;
		padding-bottom: 10px;

		.progress-bar {
			background-color: $lightblue;
		}
	}
	// -------------------- INTERESTS SECTION ------------------------------
	#interest {
		padding-top: 40px;
		background-color: $darkblue;
		color: white;
		height: 100%;
		text-align: center;

		p {
			padding-bottom: 40px;
		}

		h4 {
			color: inherit;
		}
	}
	// -------------------- XS SCREENS ------------------------------
	// date and bar labels placing
	@media (max-width: 575.98px) {
		.about-details {
			text-align: center;

			div:first-child {
				padding-bottom: 10px;
			}
		}

		.date-place {
			text-align: center;
			font-size: 0.7rem;
		}

		.bar-label {
			font-size: 0.8rem;
		}
	}


	@media (max-width: 768.98px) {

		#interest p {
			padding-bottom: 20px;
		}

		.hobby-pic-div {
			padding: 5px;
		}
	}
}

