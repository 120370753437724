@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{scroll-behavior:smooth}body{box-sizing:border-box;font-family:'Oxygen', sans-serif;color:#343434;overflow-x:hidden;position:relative}.App{min-height:calc(100vh - 60px);background-color:#F7F7F7}h1,h3{font-family:'Fredericka the Great', sans-serif}.row{margin-left:0;margin-right:0}.page-container{margin-top:60px;font-weight:200;height:100%}.page-header{text-align:center;min-height:25vh;background-color:white;-webkit-filter:drop-shadow(0rem 0.25rem 1rem rgba(0,0,0,0.1));filter:drop-shadow(0rem 0.25rem 1rem rgba(0,0,0,0.1))}.page-title{background-color:#1F6F8B;display:flex}.page-title h3{margin:auto;color:white;font-size:3rem;padding-bottom:20px}.page-description{margin:40px auto;display:flex;flex-direction:column;justify-content:center}.page-description p:first-child{font-size:1.5rem}.page-content{padding-top:60px;padding-bottom:50px}h4{font-size:2rem;font-weight:700;padding:10px;margin-bottom:30px;color:#1F6F8B;text-align:center;text-transform:capitalize}a{color:inherit}a:hover{text-decoration:none;color:inherit}@media (max-width: 767.98px){.page-content{padding-bottom:90px}#title h1{font-size:3.2rem}.App nav{-webkit-filter:initial;filter:initial}}@media (min-width: 768px){.page-title{min-height:200px}}@media (min-width: 2000px){.page-title p{margin-right:15%}}

nav{background-color:#1F6F8B;color:white;min-height:60px;-webkit-filter:drop-shadow(0.3rem 0.2rem 0.2rem rgba(0,0,0,0.2));filter:drop-shadow(0.3rem 0.2rem 0.2rem rgba(0,0,0,0.2))}#home{font-size:1.2rem}a{padding:5px 15px}a.active{font-weight:700}

#homepage #homesection{background-image:url(/static/media/background.b4d7f5ba.svg);display:flex;background-size:cover;background-position:30% 10%;background-repeat:no-repeat}#homepage #title{height:100vh}#homepage #title div{padding-top:250px}#homepage #title h1{font-size:4rem}#homepage #title h2{padding-top:5px;font-weight:300;font-size:1.7rem}#homepage #webdev{color:#0094A2}#homepage #image-profile{margin:auto}#homepage img{max-height:250px;max-width:100%;border-radius:50%;display:flex;margin-left:auto;margin-right:10%;-webkit-filter:drop-shadow(0.5rem 0.5rem 0.5rem rgba(0,0,0,0.3));filter:drop-shadow(0.5rem 0.5rem 0.5rem rgba(0,0,0,0.3))}#homepage #introduction{text-align:justify;margin:50px auto 100px}#homepage #introduction p:first-child{font-size:2.2rem;text-align:center;font-family:'Fredericka the Great', sans-serif}#homepage #introduction p{max-width:800px;margin:auto;padding:10px}#homepage #menu{padding-bottom:90px;max-width:800px;padding-left:0;padding-right:0}#homepage #menu a{background-color:#1F6F8B;color:white;font-size:1rem;font-weight:700;height:13rem;display:flex;flex-direction:column;align-items:center;justify-content:center;margin:auto;margin-bottom:50px;border-radius:2px;-webkit-filter:drop-shadow(0 0.2rem 0.25rem rgba(0,0,0,0.5));filter:drop-shadow(0 0.2rem 0.25rem rgba(0,0,0,0.5))}#homepage #menu a:hover{-webkit-filter:contrast(130%) brightness(85%) drop-shadow(0 0.2rem 0.25rem rgba(0,0,0,0.5));filter:contrast(130%) brightness(85%) drop-shadow(0 0.2rem 0.25rem rgba(0,0,0,0.5))}#homepage #menu svg{width:80%;height:20%;margin-bottom:20px}@media (max-width: 767.98px){#homepage #homesection{background-size:cover;background-position:60%}#homepage #homesection #title h1{font-size:3.2rem}#homepage #homesection #title div:first-child{padding-top:125px}#homepage #homesection #subtitle{max-width:75%}#homepage #homesection img{margin:auto}#homepage #menu a{width:85%}}@media (min-width: 768px) and (max-width: 991.98px){#homepage #menu a{height:10rem}}

#projects-page .page-description svg{font-size:3rem;margin:15px auto}#projects-page .page-description a{padding:5px;color:#0094A2}#projects-page .page-description p:nth-child(2){font-size:1.5rem;font-weight:400;margin-bottom:0}#projects-page .page-description p:nth-child(3){font-size:0.8rem}#projects-page .page-content{padding-top:0}#projects-page #tech{background-color:#0094A2;color:white;padding:35px;-webkit-filter:drop-shadow(0rem 0.5rem 0.5rem rgba(0,0,0,0.4));filter:drop-shadow(0rem 0.5rem 0.5rem rgba(0,0,0,0.4))}#projects-page #tech h4{color:inherit;padding:20px;font-weight:400}#projects-page .tech-details{display:flex;flex-direction:column;align-items:center}#projects-page .tech-details svg{font-size:2rem}#projects-page .tech-details p{padding:10px;font-size:0.8rem}#projects-page #projects{padding:25px}#projects-page #projects img:hover{-webkit-filter:brightness(70%) drop-shadow(0.2rem 0.4rem 0.5rem rgba(0,0,0,0.3));filter:brightness(70%) drop-shadow(0.2rem 0.4rem 0.5rem rgba(0,0,0,0.3))}#projects-page #projects .project-details{padding:20px}#projects-page #projects a{padding:0;position:relative;display:block}#projects-page #projects .project-legend{background-color:rgba(0,0,0,0.5);color:white;position:absolute;bottom:0;width:100%;padding:10px 0}#projects-page #projects .project-legend p{text-align:center;margin:0}#projects-page #projects .project-legend p:nth-child(1){font-weight:400}#projects-page #projects .project-legend p:nth-child(2){font-size:0.7rem}#projects-page #more-projects{background-color:#0094A2;text-align:center;color:white;font-size:1.5rem;padding:25px}

#about-page .about-category{padding-bottom:40px}#about-page .about-details{white-space:pre-wrap;margin-top:20px;margin-bottom:40px}#about-page .about-details p{margin:0}#about-page .about-details div:first-child{font-weight:400;padding-bottom:15px}#about-page .date-place{padding-top:5px;padding-bottom:10px;font-size:0.9rem;text-align:right}#about-page .about-description{font-size:0.9rem;text-align:justify}#about-page #languages{margin-bottom:50px}#about-page .bar{justify-content:center;text-align:right;margin-right:30px;padding-bottom:10px}#about-page .bar .progress-bar{background-color:#0094A2}#about-page #interest{padding-top:40px;background-color:#1F6F8B;color:white;height:100%;text-align:center}#about-page #interest p{padding-bottom:40px}#about-page #interest h4{color:inherit}@media (max-width: 575.98px){#about-page .about-details{text-align:center}#about-page .about-details div:first-child{padding-bottom:10px}#about-page .date-place{text-align:center;font-size:0.7rem}#about-page .bar-label{font-size:0.8rem}}@media (max-width: 768.98px){#about-page #interest p{padding-bottom:20px}#about-page .hobby-pic-div{padding:5px}}

.contact-page svg{color:silver}.contact-page #contact-icons{margin-bottom:20px}.contact-page #contact-icons a{display:inline-block;padding:10px;background-color:#1F6F8B;border-radius:50%;width:40px;height:40px;margin:10px}.contact-page #contact-icons a svg{color:white}.contact-page #location{margin:0}.contact-page #location span{padding-left:15px}.contact-page .container{display:flex;flex-direction:column;align-items:center;justify-content:center}.contact-page h4{font-weight:400}.contact-page abbr{font-size:0.6rem;vertical-align:top;text-decoration:none}.contact-page form{margin-top:10px;margin-bottom:70px;width:100%}.contact-page button{background-color:#0094A2;border-radius:0.25rem;border:none;margin-top:25px}.contact-page button:hover,.contact-page button:active,.contact-page button:focus{background-color:#1F6F8B;outline:none;box-shadow:none}@media (min-width: 768px){.contact-page form{width:80%}}@media (min-width: 992px){.contact-page form{width:50%}}

.footer{background-color:#1F6F8B;color:white;padding:0.7em;position:absolute;bottom:0;width:100%}.footer #contact-footer{margin-top:0.5rem;margin-bottom:0}.footer ul{margin-bottom:0;justify-content:center}.footer ul li{display:inline;padding:10px;margin-bottom:0;font-size:0.9rem}.footer ul li a{padding:0}.footer i{font-size:1.5rem;padding:0.5rem;padding-bottom:0}.footer #reference{font-size:0.6rem}@media (max-width: 575.98px){.footer ul li{padding:10px;font-size:0.8rem}}

