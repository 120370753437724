@import './variables';

// -------------------- HEADER ------------------------------

nav {
	background-color: $darkblue;
	color: white;
	min-height: $navbar-height;
	filter:drop-shadow(0.3rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2))
}

#home{
	font-size: 1.2rem;
}

a{
	padding: 5px 15px;
}

a.active{
	font-weight:700;
}