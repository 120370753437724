@import './variables';
@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');

#homepage {
	// -------------------- HOME SECTION WITH SVG BACKGROUND ------------------------------
	#homesection {
		background-image: url(../assets/images/background.svg);
		display: flex;
		background-size: cover;
		background-position: 30% 10%;
		background-repeat: no-repeat;
	}
	// TITLE AND SUBTITLE
	#title {
		height: 100vh;

		div {
			padding-top: 250px;
		}

		h1 {
			font-size: 4rem;
		}

		h2 {
			padding-top: 5px;
			font-weight: 300;
			font-size: 1.7rem;
		}
	}

	#webdev {
		color: $lightblue;
	}
	// PROFILE PICTURE
	#image-profile {
		margin: auto;
	}

	img {
		max-height: 250px;
		max-width: 100%;
		border-radius: 50%;
		display: flex;
		margin-left: auto;
		margin-right: 10%;
		filter: drop-shadow(0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.3));
	}
	// INTRODUCTION
	#introduction {
		text-align: justify;
		margin: 50px auto 100px;

		p:first-child {
			font-size: 2.2rem;
			text-align: center;
			font-family: 'Fredericka the Great', sans-serif;
		}

		p {
			max-width: 800px;
			margin: auto;
			padding: 10px;
		}
	}
	// -------------------- MENU ------------------------------
	#menu {
		padding-bottom: $footer-height-max;
		max-width: 800px;
		padding-left: 0;
		padding-right: 0;

		a {
			background-color: $darkblue;
			color: white;
			font-size: 1rem;
			font-weight: 700;
			height: 13rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: auto;
			margin-bottom: 50px;
			border-radius: 2px;
			filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.5));
		}

		a:hover {
			filter: contrast(130%) brightness(85%) drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.5));
		}

		svg {
			width: 80%;
			height: 20%;
			margin-bottom: 20px;
		}
	}
	// --------------------- SMALL SCREENS ------------------------------
	// svg adjustments and picture to center
	@media (max-width: 767.98px) {

		#homesection {
			background-size: cover;
			background-position: 60%;

			#title {
				h1 {
					font-size: 3.2rem;
				}

				div:first-child {
					padding-top: 125px;
				}
			}

			#subtitle {
				max-width: 75%;
			}

			img {
				margin: auto
			}
		}

		#menu a {
			width: 85%;
		}
	}
	//button height adjustment
	@media (min-width: 768px) and (max-width: 991.98px) {
		#menu a {
			height: 10rem;
		}
	}
}
